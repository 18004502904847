.text_data{
    display: block;
    margin-block-start: 2px;
    margin-block-end: 2px;
    margin-inline-start: 200px;
    margin-inline-end: 200px;
    text-align: left;
    font-family: 'Roboto';
    font-size: 22px;
    font-style: normal;
    font-weight: 100;
    margin-top: 14px;
    margin-bottom: 14px;

}
.img_in_spanb{
    background-image: url('/images/wifi_our_tech.jpg');
    background-position: center center;
    background-size: cover;
}
.img_in_spanf{
    background-image: url('/images/certifcate.jpg');
    background-position: center center;
    background-size: cover;
}