.parent{
    width:100%;
    height:150px;
    position:relative;
    text-align:center;
    position: relative;
    background-image: url('/images/img-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .son_of_a_bich{
    position: absolute;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
  
  }