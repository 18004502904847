.pageStyle{
    background-color: rgb(255, 251, 251);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

.buttonStyle{
    width: 100%;
    background-color: rgb(255, 255, 255); 
    display: flex;
    justify-content: center;

}
.left_button_title {
    width: 75%;
    text-align: left;

}
.right_button_icon{
    width: 50%;
    text-align: right;
}
.sow-headline {
    text-align: center;
    color: #5b5e5d;
    line-height: 1.4em;
}
.sow-headline-container:first-child{
    margin-top: 0 !important;
}
.text_data{
    display: block;
    margin-block-start: 2px;
    margin-block-end: 2px;
    margin-inline-start: 200px;
    margin-inline-end: 200px;
    text-align: left;
    font-family: 'Roboto';
    font-size: 22px;
    font-style: normal;
    font-weight: 100;
    margin-top: 14px;
    margin-bottom: 14px;
}
ul li, ol li {
    margin: 8px 0;
    margin-top: 8px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-left: 15px;
}
li{
    display: list-item;
    text-align: -webkit-match-parent;

}
ul {
    list-style-type: disc;
}
.img_in_spana {
    background-image: url(http://www.ttc-testing.com/ttcen/wp-content/uploads/coding.jpeg);
    background-position: center center;
    background-size: cover;
}
.img_in_spanb{
    background-image: url('/images/main_tech.jpg');
    background-position: center center;
    background-size: cover;
}
.img_in_spanc{
    background-image: url('/images/aboutus.webp');
    background-position: center center;
    background-size: cover;
}
.img_in_spand{
    background-image: url('/images/security.jpg');
    background-position: center center;
    background-size: cover;
}
.p{
    font-family: 'Roboto';
    font-size: 22px;
    font-style: normal;
    font-weight: 100;
    margin-top: 14px;
    margin-bottom: 14px;
}
.text_show_contenet{
    font-family: 'Roboto';
    font-size: 22px;
    font-style: normal;
    font-weight: 100;
    margin-top: 14px;
    margin-bottom: 14px;


}
body, button, input, select, textarea {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7;
    color: #777777;
}
.list_in{
    display: block;
    margin-block-start: 2px;
    margin-block-end: 2px;
    margin-inline-start: 5px;
    margin-inline-end: 5px;
    text-align: left;
    font-family: 'Roboto';
    font-size: 22px;
    font-style: normal;

}