.parent{
  width:100%;
  height:150px;
  position:relative;
  text-align:center;
  position: relative;
  background-image: url('/images/img-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.son_of_a_bich{
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -50%);
}
.img_in_spanr{
  background-image: url('/images/audit.jpg');
  background-position: center center;
  background-size: cover;
}
.img_in_spang{
  background-image: url('/images/installition.webp');
  background-position: center center;
  background-size: cover;
}
.img_in_spanf{
  background-image: url('/images/support.jpeg');
  background-position: center center;
  background-size: cover;
}