.center{
    display: -webkit-flex;
    display: flex;
    width: 100%;
}
.left_button_title {
    width: 75%;
    text-align: left;
}
.right_button_icon{
    width: 25%;
    text-align: right;

}
